/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --linear: linear-gradient(20deg,rgba(90,19,108,0) -1.3%,rgba(90,19,108,.61) 32.97%,rgba(109,18,51,.6) 57.76%,rgba(109,18,51,0) 99.94%);
  --linear2: linear-gradient(50deg, rgba(45, 26, 255, 1) 0%, rgba(255, 213, 255, 1) 20%, rgba(87, 246, 255, 1) 75%, rgba(0,212,255,1) 100%);
  /* --background: #ffffff20; */
  --white: #ffffff;
}

html {
  max-width: 1140px;
  min-width: 80vw;
  margin: 0px auto;
  padding: 0;
}

* {
  /* Fonts */
  /* font-family: "Cascadia Code", monospace, sans-serif; */
  font-size: 16px;
  scroll-behaviour: smooth;
}

body {
  padding: 1em;
  background: black;
  color: white;
  background-image: var(--linear);
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.blob-container {
  position: absolute;
  justify-content: center;
  display: flex;
  height: 100vh;
  width: 49vw;
  top: 0px;
  /* margin: 0 auto; */
  overflow: hidden;
  z-index: 0;
}

canvas {
  position: absolute;
  top: 10px;
  /* left: 20; */
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}


section {
  padding: 1em 0em;
  min-height: 80vh;
  align-items: center;
}


h1 {
  font-size: 3.5rem;
  font-weight: 700;
}


h2{
  font-size: 2.8rem;
  font-weight: 700;
}

h3{
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-size: 1.75rem;
  font-weight: 600;
}

h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

h5 {
  font-size: 1.25rem;
  font-weight: 600;
}

h6 {
  font-size: 1.3rem;
  font-weight: 600;
}

p{
  font-weight: 400;
}

p > a {
  color: #53f4ff;
  text-decoration: underline;
  font-style: italic;
}

ul {
  list-style-type: square;
  padding: 0.25rem 2rem;
  margin: 1rem 0rem;
}

button, button[type=submit]{
  background: #ffffff20;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0rem;
  border-radius: 0.25rem;
}

button:hover, button[type=submit]:hover{
  background: #ffffff10;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0rem;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  body {
    padding: 0em;
  }  
  h1 {
      font-size: 2rem;
  }
  .blob-container {
    display: none;
  }
  canvas {
    position: relative;
    /* top: 10px; */
    /* left: 20; */
    /* right: 0; */
    z-index: -1;
    height: 100%;
    width: 100%;
  }

  section {
    padding: 1em 0em;
    height: 100%;
    align-items: center;
  }
    
}

/* Scroll Bar */
body::-webkit-scrollbar {
  width: 0em;
}

@layer base{
  .link{
    @apply hover:underline underline-offset-4 block;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}